import { LoginRequest } from "~/pages/api/auth/request";
import axios from "axios";
import {
  UpdateTaskRequest,
  UpdateTaskResponse,
} from "~/pages/api/tasks/[taskId]";
import Task from "~/models/Task";
import { AuthUser } from "next-firebase-auth";
import { CreateTaskReminderRequest } from "~/pages/api/tasks/[taskId]/reminder";
import User, { UserRecord } from "~/models/User";

axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_BASE_URL;

export default class apiClient {
  static async requestLogin(req: LoginRequest) {
    await axios.post("/auth/request", req);
  }
  static async login(token: string) {
    // NOTE: Authorization does not have Bearer in next-firebase-auth implementation
    await axios.post("/auth/login", {}, { headers: { Authorization: token } });
  }
  static async initializeUser(token: string) {
    await axios.post(
      "/auth/init",
      {},
      { headers: { Authorization: "Bearer " + token } }
    );
  }
  static async sendInvitation(email: string, token: string) {
    await axios.post(
      "/auth/invite",
      { email },
      { headers: { Authorization: "Bearer " + token } }
    );
  }
  static async getUsers(
    params: Partial<UserRecord>,
    token: string
  ): Promise<User[]> {
    const res = await axios.get("/users", {
      params,
      headers: { Authorization: "Bearer " + token },
    });
    return res.data.users;
  }
  static async updateTask(
    authUser: AuthUser,
    taskId: string,
    req: UpdateTaskRequest
  ): Promise<Task> {
    const token = await authUser.getIdToken();
    const res = await axios.post<UpdateTaskResponse>(`/tasks/${taskId}`, req, {
      headers: { Authorization: "Bearer " + token },
    });
    return res.data.task;
  }
  static async createReminder(
    authUser: AuthUser,
    taskId: string,
    req: CreateTaskReminderRequest
  ) {
    const token = await authUser.getIdToken();
    const res = await axios.post<CreateTaskReminderRequest>(
      `/tasks/${taskId}/reminder`,
      req,
      { headers: { Authorization: "Bearer " + token } }
    );
    return res.data;
  }
}
