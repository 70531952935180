import React, { ButtonHTMLAttributes } from "react";
import styles from "./Button.module.scss";

interface ButtonProps {
  buttonText: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  className?: string;
  onClick?: () => void;
  href?: string;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  buttonText,
  className = "",
  onClick,
  href,
  type = "button",
  disabled = false,
}) => {
  if (href)
    return (
      <a
        className={`${styles.button} ${className}`}
        data-disabled={disabled}
        href={href}
      >
        {buttonText}
      </a>
    );
  else
    return (
      <button
        type={type}
        className={`${styles.button} ${className}`}
        disabled={disabled}
        onClick={onClick}
      >
        {buttonText}
      </button>
    );
};

export default Button;
