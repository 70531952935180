import React from "react";

import Button from "~/components/atoms/Button";
import styles from "./PopupModal.module.scss";

type PopupModalSize = "small" | "large";
interface ButtonProps {
  buttonText: string;
  onClick: () => void;
}

interface PopupModalProps {
  heading?: string;
  subheading?: string;
  buttons?: ButtonProps[];
  size?: PopupModalSize;
  onClickOut?: () => void;
}

const PopupModal: React.FC<PopupModalProps> = ({
  heading,
  subheading,
  buttons = [],
  size = "large",
  onClickOut,
  children,
}) => {
  const onClickWrapper = (e: React.MouseEvent) => {
    if (onClickOut && e.target === e.currentTarget) onClickOut();
  };

  return (
    <div className={styles.popupModalWrapper} onClick={onClickWrapper}>
      <div
        className={
          size === "large" ? styles.popupModal : styles.popupModalSmall
        }
      >
        {heading ? <h2>{heading}</h2> : null}
        {subheading ? <h3>{subheading}</h3> : null}
        <div>{children ? children : null}</div>
        <div
          className={
            size === "large"
              ? styles.popupModal__buttons
              : styles.popupModalSmall__buttons
          }
        >
          {buttons?.length > 0 &&
            buttons.map(({ buttonText, onClick }) => (
              <Button
                key={buttonText}
                buttonText={buttonText}
                onClick={onClick}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default PopupModal;
