import React, { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "~/components/atoms/Button";
import styles from "./LoginForm.module.scss";

interface LoginFormProps {
  label: string;
  labelSmall?: string;
  onSubmit: (email: string) => Promise<void>;
}

const LoginForm: React.FC<LoginFormProps> = ({
  label,
  labelSmall,
  onSubmit,
}) => {
  const { t } = useTranslation(["login"]);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError("");
  };

  const handleOnSubmit = async (e: FormEvent) => {
    try {
      e.preventDefault();
      await onSubmit(email);
      setEmail("");
    } catch (e) {
      setError(t("login:request.error"));
    }
  };

  return (
    <form className={styles.loginForm} onSubmit={handleOnSubmit}>
      <label htmlFor="email">
        {label}
        {labelSmall ? <small>{labelSmall}</small> : null}
      </label>
      {error ? <span className={styles.loginForm__error}>{error}</span> : null}
      <input
        id="email"
        type="email"
        value={email}
        onChange={handleOnChange}
        data-error={!!error}
      />
      <Button type="submit" buttonText={t("login:invitation.button")} />
    </form>
  );
};

export default LoginForm;
